import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Modal from "react-modal";
import { Link } from "gatsby";

import { openJoinUsPopup } from "../utils/utils";

const customStyles = {
  content: {
    width: "100%",
    maxWidth: 800,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99999,
    border: "none",
  },
};

function IndexPage() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Layout>
      <SEO
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title="Hibot - Home "
      />

      <header className="header">
        <div className="container relative flex flex-col">
          <div className="text-right flex-1 flex flex-col items-end mt-48">
            <h1 className="text-5xl leading-none md:leading-tight font-bold mb-5 text-white">
              The future of infrastructure inspection
              <br />
              and maintenance is smart
            </h1>
            <div
              className="border-b-2 inline-block mb-5"
              style={{ width: 190 }}
            ></div>
            <p className="hidden md:block text-lg text-white mb-5 max-w-2xl">
              Failure prediction is the key to efficiently assure the
              reliability of aging infrastructure and reduce downtime. HiBox
              seamlessly integrates HiBot’s Smart Tools in the field with
              autonomous navigation, data acquisition and management, real-time
              visualisation of data, Al-based defect detection, and more.
            </p>
            <p>
              <a
                className="flex items-center btn bg-primary text-white inline-block px-5 py-3 shadow-md cursor-pointer"
                onClick={openModal}
              >
                <span className="icon-arrow-right--white mr-2"></span>
                <span>Watch the video</span>
              </a>
            </p>
          </div>
          <div className="hidden md:flex my-6 text-white uppercase font-mono">
            <div className="w-1/2">SMART INSPECTION AND MAINTENANCE</div>
            <div className="w-1/2 text-right">
              AEROSPACE · OIL/GAS/CHEMICAL · NUCLEAR POWERPLANTS
            </div>
          </div>
        </div>
      </header>
      <h2 className="text-center text-primary uppercase my-4">Partners</h2>
      <section>
        <div className="container">
          <div className="flex justify-center flex-wrap py-5">
            <div className="p-2">
              <img
                alt="Mirai Souzou"
                className="block"
                src={require("../images/clients/mirai.svg")}
                style={{ height: 65, width: "auto", marginRight: 10 }}
              />
            </div>
            <div className="p-2">
              <img
                alt="Fuyo Lease"
                className="block"
                src={require("../images/clients/fuyo.svg")}
                style={{
                  height: 40,
                  width: "auto",
                  marginRight: 10,
                  marginTop: 10,
                }}
              />
            </div>
            {/* <div className="p-2">
              <img
                alt="Tepco"
                className="block"
                src={require("../images/clients/tepco.svg")}
                style={{ height: 40, width: "auto", marginRight:14, marginTop:8 }}
              />
            </div> */}
            <div className="p-2">
              <img
                alt="Takahata"
                className="block"
                src={require("../images/clients/takahata.svg")}
                style={{
                  height: 30,
                  width: "auto",
                  marginRight: 14,
                  marginTop: 12,
                }}
              />
            </div>
            {/* <div className="p-2">
              <img
                alt="Tokyo Power Technology"
                className="block"
                src={require("../images/clients/tppt.svg")}
                style={{ height: 35, width: "auto", marginRight:14, marginTop:10 }}
              />
            </div> */}

            <div className="p-2">
              <img
                alt="Mitsubishi UFJ Capital"
                className="block"
                src={require("../images/clients/mucap.png")}
                style={{
                  height: 40,
                  width: "auto",
                  marginRight: 12,
                  marginTop: 10,
                }}
              />
            </div>
            <div className="p-2">
              <img
                alt="LiNKX"
                className="block"
                src={require("../images/clients/LiNKX_logo-01.png")}
                style={{
                  height: 50,
                  width: "auto",
                  marginRight: 12,
                  marginTop: 10,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="border-b"></div>
      </div>

      <section id="news" className="py-10">
        <div className="container">
          <div className="md:flex mb-4">
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-4xl font-bold mb-4 pl-4">News</h2>
              {/* <div className="p-2">
                <img
                  alt="HiBot Video"
                  className="block"
                  src={require("../images/vthum@2x.jpg")}
                  style={{ height: 120, width: "auto", marginRight:10, marginTop:10 }}
                />
              </div> */}
              <p className="mb-4 border-l-4 border-gray-200 pl-4">
                <h3>
                  HiBot rebirth, our Robot as a Service (RaaS) platform launch
                  globally
                </h3>
                HiBot announces investment from Innovations and Future Creation
                Inc. (Mirai Souzou), Fuyo General Lease Co., Ltd, Takahata
                Precision, Tokyo Power Technology aiming at expanding the
                business of infrastructure maintenance at a global scale with
                robotics and AI.
              </p>
              <p>
                <a onClick={openModal} className="cursor-pointer">
                  <span className="icon-arrow-right--primary mr-2 ml-4"></span>
                  Watch the video
                </a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-xl mt-2 font-bold">Press Relese</h2>
              <div className="flex flex-row border-b items-center">
                <div className="p-3">
                  <img
                    alt="PDF Icon"
                    className="inline-block"
                    src={require("../images/icons/icon-pdf.svg")}
                    style={{ width: 30, height: 37 }}
                  />
                </div>
                <div className="p-3 pl-0 text-gray-800">16th April 2020</div>
                <div className="p-3 ml-auto">
                  {/* <a href="#" className="underline mr-1">
                    View
                  </a>{" "} */}
                  <a
                    href="https://www.hibot.co.jp/pdf/hibot_april2020_press02.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Download PDF
                  </a>
                </div>
              </div>
              <div className="flex flex-row border-b items-center">
                <div className="p-3">
                  <img
                    alt="PDF Icon"
                    className="inline-block"
                    src={require("../images/icons/icon-pdf.svg")}
                    style={{ width: 30, height: 37 }}
                  />
                </div>
                <div className="p-3 pl-0 text-gray-800">26th March 2020</div>
                <div className="p-3 ml-auto">
                  <a
                    href="https://www.hibot.co.jp/pdf/hibot_march2020_press01.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Download PDF
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16" style={{ background: "#FAFAFA" }}>
        <div className="container">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-5 leading-tight">
              Subscribe to our newsletter and get updated
            </h2>
            <div>
              <form
                action="https://hibot.us19.list-manage.com/subscribe/post?u=f2bcfc4bf7904b85419f8cf48&amp;id=eb2bdd6bf0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div className="flex overflow-hidden bg-white border border-gray-400 max-w-lg mx-auto rounded-full h-12 shadow-sm">
                  <img
                    alt="E-mail Icon"
                    className="block ml-4 mr-3"
                    src={require("../images/icons/icon-email.svg")}
                    style={{ width: 30 }}
                  />
                  <input
                    placeholder="Enter your email here"
                    className="flex-1 outline-none bg-white"
                    type="email"
                    name="EMAIL"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_f2bcfc4bf7904b85419f8cf48_eb2bdd6bf0"
                      tabIndex="-1"
                      value=""
                    />
                  </div>
                  <button className="hidden sm:block bg-primary text-white rounded-full px-6 -m-px outline-none">
                    Get informed
                  </button>
                </div>
              </form>
              <button className="sm:hidden bg-primary text-white mt-2 rounded-full px-6 py-3 outline-none">
                Get informed
              </button>
            </div>
          </div>
        </div>
      </section>

      <h2 className="text-center text-primary uppercase my-12">Products</h2>

      <section id="products" className="py-10">
        <div className="container">
          <div className="md:flex">
            <div className="mb-5 md:mb-0 md:w-1/2 flex items-center">
              <img
                alt="Float Arm"
                className="block"
                src={require("../images/float-arm.jpg")}
              />
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="product-card">
                <h3 className="mb-6">
                  <img
                    alt="Float Arm"
                    className="block"
                    src={require("../images/float-arm-heading.svg")}
                    style={{ width: "85%" }}
                  />
                </h3>
                <p className="mb-8">
                  <strong>Float Arm</strong> is HIBot’s new snake manipulator,
                  designed for long-reach applications in confined spaces. With
                  HiBot’s proprietary snake-motion control, it is able to
                  navigate in complex environments and acquire detailed data for
                  inspection and maintenance. Float Arm is lightweight, fast and
                  highly portable, making it an ideal tool for integration with
                  other systems already in operation in the field, or simply as
                  a stand-alone inspection device.
                </p>
                <div className="flex justify-between mb-6">
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Long Reach"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-reach.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Long Reach
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Weight Compensation"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-weight.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Weight Compensation
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Snake-Like Mobility"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-snake.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Snake-Like Mobility
                    </div>
                  </div>
                </div>
                <table className="features-table">
                  <tr>
                    <td>Extended length</td>
                    <td>5m</td>
                  </tr>
                  <tr>
                    <td>Mass</td>
                    <td>25kg</td>
                  </tr>
                  <tr>
                    <td>Payload</td>
                    <td>1.5kg</td>
                  </tr>
                  <tr>
                    <td>Number of links</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Possible sensors</td>
                    <td>HD camera, Thermal camera, UT probe, LIDAR</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="border-b-2 border-primary block mx-auto"
        style={{ width: 220 }}
      ></div>

      <section className="py-10">
        <div className="container">
          <div className="md:flex flex-row-reverse">
            <div className="md:w-1/2">
              <img
                alt="Thesbot-Dual"
                className="block"
                src={require("../images/thesbot.jpg")}
              />
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="product-card">
                <div className="mb-4">
                  <h3 className="leading-none text-3xl text-gray-700 font-bold mb-0">
                    THESBOT-Dual
                  </h3>
                  <h4 className="text-primary font-bold">Pipe Inspection</h4>
                </div>
                <p className="text-gray-700 mb-8">
                  Its unique traction concept allows it to move in complex
                  geometries, such as consecutive bends in different directions,
                  vertical pipes and weld beads. THESBOT-Dual can be used for
                  visual inspection, as well as with RFT, UT probes and more.
                </p>
                <div className="flex justify-between mb-6">
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Mobility in all directions"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-mobility.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Mobility in all directions
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="IPX7 water resistance"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-ipx7.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      IPX7 water resistance
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Real-time inspection"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-realtime.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Real-time inspection
                    </div>
                  </div>
                </div>
                <table className="features-table">
                  <tr>
                    <td>Pipe size</td>
                    <td>6&quot;</td>
                  </tr>
                  <tr>
                    <td>Length</td>
                    <td>757mm</td>
                  </tr>
                  <tr>
                    <td>Mass</td>
                    <td>6kg</td>
                  </tr>
                  <tr>
                    <td>Traction force</td>
                    <td>245N</td>
                  </tr>
                  <tr>
                    <td>Speed</td>
                    <td>100mm/s</td>
                  </tr>
                  <tr>
                    <td>Tether</td>
                    <td>50m (extendable to 100m)</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <h2 className="text-center text-primary uppercase mb-5">Services</h2>

      <section id="services" className="services-section py-16">
        <div className="container">
          <img
            alt="HiBox Logo"
            className="block mx-auto mb-10"
            src={require("../images/hibox.svg")}
            style={{ width: 150, height: 150 }}
          />
          <div className="md:flex mb-4">
            <div className="mb-5 md:mb-0 md:w-1/2 md:p-5">
              <h3 className="text-4xl mb-3 font-bold">HiBox - Smart MRO</h3>
              <p className="mb-4">
                Failure prediction is the key to efficiently assure the
                reliability of aging infrastructure and reduce downtime. HiBox
                seamlessly integrates HiBot’s Smart Tools in the field with
                autonomous navigation, data acquisition and management,
                real-time visualization of data, Al-based defect detection, and
                more. In addition, with HiBox, off-site specialists can access
                the inspection data remotely, and more complex and customizable
                machine learning algorithms can be used to identify patterns and
                compare data with previous inspections. This allows users to
                predict when certain failure modes may happen before they
                actually do. HiBox also keeps track of the system health,
                assuring that the smart tools will not fail on the site, and
                generates inspection reports automatically, saving weeks of
                work.
              </p>
              <p>
                <a href="#contact">Contact us to learn more</a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Autonomous Navigation"
                  className="mr-4"
                  src={require("../images/icons/icon-service-autonomous.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  Autonomous Navigation
                </span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Inspection data comparison"
                  className="mr-4"
                  src={require("../images/icons/icon-service-data.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  Inspection data comparison
                </span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Robot health monitoring"
                  className="mr-4"
                  src={require("../images/icons/icon-service-monitoring.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  Robot health monitoring
                </span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Autonomous generation of inspection reports"
                  className="mr-4"
                  src={require("../images/icons/icon-service-reports.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  Autonomous generation of inspection reports
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-32">
        <div className="container text-center">
          <img
            alt="HiBot logo"
            className="inline-block mb-10"
            src={require("../images/hb-logo.svg")}
            style={{ width: 150, height: 150 }}
          />
          <h2 className="text-4xl font-bold mb-3 leading-none">
            Join our team, we are growing
          </h2>
          <p className="max-w-2xl mx-auto mb-10">
            We are looking for talented engineers to help us build the next
            generation robotics platform for the inspection and maintenance
            industries.
          </p>
          <p>
            <button
              className="btn w-40 bg-primary inline-block text-white px-4 py-2 focus:outline-none"
              onClick={openJoinUsPopup}
            >
              Join us
            </button>
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <img
            alt="Story cover"
            className="inline-block mb-10"
            src={require("../images/story-cover.jpg")}
          />
        </div>
      </section>

      <section id="about" className="py-16">
        <div className="container">
          <div className="md:flex mb-4">
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-4xl font-bold mb-4">Our story</h2>
              <p>
                HiBot was created at the Tokyo Institute of Technology in 2004
                by Prof. Shigeo Hirose, Michele Guarnieri, Paulo Debenest and
                other members, in order to make some of the cutting-edge
                technology from the academic world available to industrial
                applications. Over the years, we have specialized in 3D
                applications: Dirty, Demanding, Demeaning. We strongly believe
                that robots should be used as tools for humans where it’s too
                dangerous, or simply impossible, for people to go. The
                applications may range from inspection of pipes to maintenance
                of underground infrastructure, predictive maintenance of
                bridges, works in nuclear power plants, search &amp; rescue
                missions and more!
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="mt-10 timeline">
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2004</div>
                    <div>
                      Establishment of HiBot at the Tokyo Institute of
                      Technology.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2010</div>
                    <div>
                      Robot Award from the Japanese Ministry of Industry for
                      Project Expliner.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2014</div>
                    <div>
                      Technology Pioneer recognition from the World Economic
                      Forum.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2016</div>
                    <div>
                      CT-Arm successfully utilised inside Fukushima power plant
                      reactor 1.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="container text-center">
          <h2 className="text-4xl leading-tight font-bold mb-0">
            Meet the team
          </h2>
          <p className="mb-10">International from day one</p>
          <div className="md:flex justify-center flex-wrap">
            <div className="md:w-full p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/michele-guarnieri@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Michele Guarnieri</div>
              <div className="text-xs uppercase">Co-founder, CEO</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/sato@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Takumi Sato </div>
              <div className="text-xs uppercase">CFO</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/kato@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Tomokuni Takayama</div>
              <div className="text-xs uppercase">External board member </div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/takayama@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Yusuke Kato</div>
              <div className="text-xs uppercase">Auditor</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/hirose@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Shigeo Hirose</div>
              <div className="text-xs uppercase">Co-founder, chairman</div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/paulo@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Paulo Debenest </div>
              <div className="text-xs uppercase">
                Co-founder, executive officer <br /> (project management)
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/yamada@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Hiroya Yamada</div>
              <div className="text-xs uppercase">
                executive officer <br /> (innovation and R&amp;D)
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/yonemura@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Hidehiko Yonemura</div>
              <div className="text-xs uppercase">
                executive officer <br />
                (productization)
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/kitano@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Satoshi Kitano</div>
              <div className="text-xs uppercase">
                executive officer <br />
                (in-pipe solutions){" "}
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/azegami@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">Tetsu Azegami</div>
              <div className="text-xs uppercase">executive officer (sales)</div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="py-16">
        <div className="container">
          <div className="md:flex flex-row-reverse mb-4">
            <div className="md:w-1/2 md:p-10 mb-5 md:mb-0">
              <h2 className="text-4xl leading-tight font-bold mb-5">
                Get in touch
              </h2>
              <p className="mb-10">
                <strong>HiBot - Head Office</strong>
              </p>
              <p className="mb-4">
                HiBot
                <br />
                Watanabe Corp. Bldg. 4F
                <br />
                Shinagawa-ku,
                <br />
                Kita Shinagawa 5-9-15
                <br />
                141-0001 Tokyo, Japan
                <br />
                <br />
                TEL: +81-(3)-5791-7526
                <br />
                FAX: +81-(3)-5791-7527
              </p>
              <p>
                <a href="#map" className="underline">
                  View on the map
                </a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="w-120 flex shadow-lg flex-col bg-cover bg-center justify-content bg-white p-6 rounded pt-8 pb-8">
                <form
                  method="post"
                  action="https://getform.io/f/f3abf5cc-3d35-4948-9881-e5c983b34e21"
                >
                  <label>
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mt-4 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="Full Name"
                      aria-label="Full name"
                      type="text"
                      name="name"
                      id="name"
                    />
                  </label>
                  <label>
                    {/* Email */}
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="Email"
                      aria-label="Email"
                      type="email"
                      name="email"
                      id="email"
                    />
                  </label>
                  <label>
                    {/* Phone numnber */}
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="Phone number"
                      aria-label="tel"
                      type="tel"
                      name="te;"
                      id="tel"
                    />
                  </label>
                  <label>
                    {/* Message */}
                    <textarea
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3  py-1 px-2 leading-tight focus:outline-none"
                      placeholder="Type your message"
                      name="message"
                      id="message"
                      rows="6"
                    />
                  </label>
                  <div className="flex mt-4">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="agreement"
                      value="agree"
                    />
                    <p className="text-gray-700">
                      Accept the &nbsp;
                      <Link
                        to="/privacy-policy/"
                        className="no-underline text-teal hover:text-teal-light"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                  <button
                    className="btn bg-primary pt-3 pb-3 mt-0 w-full text-white hover:bg-teal-light rounded-full "
                    type="submit"
                  >
                    Send
                  </button>
                  {/* <input type="reset" value="Clear" /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12973.454265532098!2d139.7344174!3d35.6187627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2eb205e9a80a57e1!2zSGlCb3Qg44OP44Kk44Oc44OD44OI!5e0!3m2!1sen!2sjp!4v1584587319659!5m2!1sen!2sjp"
          width="100%"
          height="600"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullscreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <button onClick={closeModal} className="uppercase mb-1 font-bold">
          close
        </button>

        <div className="aspect-ratio-16by9">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/6iB0YOhTY-I"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
            className="aspect-ratio-inside"
          ></iframe>
        </div>
      </Modal>
    </Layout>
  );
}

export default IndexPage;
